<template>
  <div class="page-box">
    <a-spin :spinning="loading">
      <a href="javascript:;" class="btn-back" @click="$closeCurrentView">
        <icon-svg class="icon-back" icon-class="icon-back" />返回
      </a>
      <p class="order-info">
        <span>订单号：{{orderDetail.orderNo}}</span>
        <span>下单时间：{{orderDetail.createTime}}</span>
        <span>{{handlePayMode(orderDetail.payMode)}}</span>
      </p>
      <div class="order-state">
        <div class="state-info-box">
          <div class="state-info">
            <p class="info-tit">{{loading ? '加载中...' : stateInfo.info}}</p>
            <p class="info-info" v-if='stateInfo.infoTip'>{{stateInfo.infoTip}}</p>
            <p class="info-info" v-if='orderDetail.orderState && orderDetail.orderType === 7'>请根据收货人信息与买家沟通，尽快进行上门服务。</p>
            <template v-if="orderDetail.orderState === 0">
              <p class="info-info">如买家未在
                <a-statistic-countdown class="count-down" :value="orderDetail.endDate" format="m 分钟 s 秒" @finish="getDetail" /> 内付款，订单将按照设置逾期自动关闭。
              </p>
            </template>
            <p v-if="orderDetail.orderState === 2 && orderDetail.returnState == null" class="info-info">如买家未在
              <a-statistic-countdown class="count-down" :value="orderDetail.endDate" format="m 分钟 s 秒" @finish="getDetail" /> 内申请退款，交易将自动完成。
            </p>
            <template v-if="orderDetail.wholesaleShopId === shopId">
              <a-button class="action" type="primary" v-if="orderDetail.orderState === 1" @click="dialogDeliveryShow = true">
                发货
              </a-button>
              <a-button class="action" type="primary" v-if="orderDetail.orderState === 2" @click="updateDeliveryShow = true">
                修改物流
              </a-button>
            </template>
            <template v-else>
              <a-button class="action" v-if="orderDetail.orderState === 0" type="primary" @click="handlePay(orderDetail.orderWholesaleInfoId)">付款</a-button>
              <a-popconfirm v-if="orderDetail.orderState === 0" placement="topRight" title="确定要取消订单吗？" ok-text="确定" cancel-text="取消" @confirm="closeOrder(orderDetail.orderWholesaleInfoId)">
                <a-button class="action" type="primary" :loading="closing === orderDetail.orderWholesaleInfoId">关闭交易</a-button>
              </a-popconfirm>
              <a-button class="action" v-if="orderDetail && orderDetail.orderState > 0 && orderDetail.orderState < 3 && [-1, -2, undefined].includes(orderDetail.returnState)" type="primary" @click="$router.push(`/purchase/service_apply/${orderDetail.orderWholesaleInfoId}/${orderDetail.item.orderWholesaleItemId}`)">申请售后</a-button>
              <a-popconfirm v-if="orderDetail.orderState === 2" placement="topRight" title="确认收货后将无法维权，确定吗？" ok-text="确定" cancel-text="取消" @confirm="confirmOrder(orderDetail.orderWholesaleInfoId)">
                <a-button class="action" type="primary" :loading="confirming === orderDetail.orderWholesaleInfoId">确认收货</a-button>
              </a-popconfirm>
            </template>
          </div>
          <div class="state-progress" v-if="orderDetail.orderState !== 4">
            <a-steps :current="orderDetail.orderState===5?1:orderDetail.orderState===6&&orderDetail.orderType===7?1: orderDetail.orderState===6&&orderDetail.orderType===7? 3 : orderDetail.orderState">
              <template slot="progressDot" slot-scope="{ index, status }">
                <a-icon v-if="status === 'finish'" class="icon-finish" type="check-circle" />
                <span class="icon-wait" v-else>{{index + 1}}</span>
              </template>
              <a-step title="买家下单" :description="orderDetail.orderTime" />
              <a-step title="买家付款" :description="orderDetail.payTime" />
              <a-step v-if='orderDetail.orderType !== 6' :title="orderDetail.orderType === 7?'到店消费':'商家发货'" :description="orderDetail.deliveryTime" />
              <a-step title="交易成功" :description="orderDetail.finishTime" />
            </a-steps>
          </div>
        </div>
        <div v-if="orderDetail.returnState != null" class="tips service">
          售后状态：
          <a-button type="link" @click="$router.push(`/purchase/service_detail/${orderDetail.orderWholesaleReturnId}${orderDetail.wholesaleShopId === shopId ? '/yes' : ''}`)">
            {{getUserReturnText(orderDetail.returnState)}}
          </a-button>
        </div>
        <div class="tips" v-if="stateInfo.tip">
          <p class="label">友情提醒：</p>
          <p v-html="stateInfo.tip"></p>
        </div>
      </div>
      <div class="logistics-info" v-if="orderDetail.orderState === 2 && (orderDetail.deliveryMode === 0 || orderDetail.deliveryMode == null)">
        <a-row>
          <a-col :span="9">
            <p class="info-item">发货方式：快递</p>
            <p class="info-item">发货人：快递助手</p>
            <p class="info-item">发货时间：{{orderDetail.deliveryTime}}</p>
            <p v-if="orderDetail.logisticsNo" class="info-item">运单号：{{orderDetail.logisticsCompName}} {{orderDetail.logisticsNo}}</p>
          </a-col>
          <a-col :span="15">
            <p class="info-item">物流状态：<span>{{handleDeliveryStatus(orderDetail.logisticsTrack.deliverystatus)}}</span></p>
            <div class="track-list">
              <a-timeline v-if="trackList.length">
                <a-timeline-item v-for="(item, i) in trackList" :key="`${item.time}${i}`">
                  {{item.status}}<br>{{item.time}}
                </a-timeline-item>
              </a-timeline>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="receive-info">
        <a-row :gutter="16">
          <a-col :span="6" v-if='orderDetail.receiverPhone||orderDetail.receiverName'>
            <p class="info-title">收货人信息</p>
            <p class="info-item">收货人：<span>{{orderDetail.receiverName}}</span></p>
            <p class="info-item">联系电话：<span>{{orderDetail.receiverPhone}}</span></p>
            <p class="info-item">收货地址：<span>{{orderDetail.receiverProvince}}{{orderDetail.receiverCity}}{{orderDetail.receiverCounty}} {{orderDetail.receiverAddr}}</span></p>
            <p class="info-item" v-if='orderDetail.orderType === 6 && orderDetail.serviceStartTime && orderDetail.serviceEndTime '>上门时间：<span style='color:#1890ff;font-weight:500;'>{{orderDetail.serviceStartTime.substr(0,orderDetail.serviceStartTime.length-3) + '~'+orderDetail.serviceEndTime.substr(-8,5)}}</span></p>
          </a-col>
          <a-col :span="6">
            <p class="info-title">配送信息</p>
            <p class="info-item">配送方式：{{orderDetail.deliveryMode === 0?'物流配送':'自行配送'}}</p>
            <p class="info-item" v-if="orderDetail.deliveryMode === 1&&orderDetail.deliveryName">配送人：{{orderDetail.deliveryName }}</p>
            <p class="info-item" v-if="orderDetail.deliveryMode === 1&&orderDetail.deliveryPhone ">联系方式：{{orderDetail.deliveryPhone }}</p>
          </a-col>
          <a-col :span="6">
            <p class="info-title">付款信息</p>
            <p class="info-item">付款状态：{{orderDetail.payTime || orderDetail.realAmount === 0 ? '已支付' : '未付款'}}</p>
            <template v-if="orderDetail.payTime">
              <p class="info-item">实付金额：<span>{{orderDetail.realAmount}}元</span></p>
              <p class="info-item">付款方式：<span>{{handlePayMode(orderDetail.payMode)}}</span></p>
              <p class="info-item">付款时间：<span>{{orderDetail.payTime}}</span></p>
            </template>
          </a-col>
          <a-col :span="6">
            <p class="info-title">买家信息</p>
            <p class="info-item">买家：<span>{{orderDetail.buyShopName}}</span></p>
            <p class="info-item">买家留言：<span>{{orderDetail.buyerNote||'-'}}</span></p>
          </a-col>
        </a-row>
      </div>
      <div class="goods-price-info">
        <a-table v-if="orderDetail && orderDetail.item" bordered :columns="columns" :dataSource="[{...orderDetail.item}]" rowKey="productId" :pagination="false">
          <template #goodsName="record">
            <div class="goods-info">
              <base-img class="goods-img" :src="record.productSkuPic||record.productMainPic" width="60" height="60" oss-style="60_60" />
              <div>
                <p class="goods-name el2">{{record.productName}}</p>
                <p class="sku-info">规格：{{record.productSkuName}}</p>
              </div>
            </div>
          </template>
          <template #deliveryState="">
            {{(orderDetail.deliveryMode === 0 && orderDetail.logisticsNo)||(orderDetail.deliveryMode === 1 && orderDetail.deliveryPhone) ? '已发货' : '未发货'}}
          </template>
        </a-table>
        <div class="price-info">
          <p>商品总价：<span>¥{{orderDetail.totalAmount || 0}}</span></p>
          <p>运费：<span>¥{{orderDetail.logisticsAmount || 0}}</span></p>
          <p>优惠：<span>¥{{orderDetail.discountAmount || 0}}</span></p>
          <p class="actual">实收金额：<span>¥{{orderDetail.realAmount || 0}}</span></p>
        </div>
      </div>
    </a-spin>

    <dialog-delivery is-batch :show.sync="dialogDeliveryShow" :delivery-info="orderDetail.orderWholesaleInfoId" @update="getDetail" />

    <dialog-update-delivery is-batch-sell :show.sync="updateDeliveryShow" :delivery-info="orderDetail.orderWholesaleInfoId" @update="getDetail" />

    <a-modal v-model="showPayModalFlag" @cancel="showPayModalFlag = false, pendingPay = ''" @ok="doPay" :confirm-loading="paying">
      <ModalBatchPay ref="batchPay" :order-no="pendingPay" />
    </a-modal>
  </div>
</template>

<script>
import { order } from '@/api'
import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api'
import { payModes, deliveryStatus, orderReturnStates } from '@/utils/constants'
import DialogDelivery from '../order/DialogDelivery'
import DialogUpdateDelivery from '../order/DialogUpdateDelivery'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { wrapAwait } from '@/utils/tools'
import ModalBatchPay from '@/views/purchase/ModalBatchPay'
import { message } from 'ant-design-vue'

const stateDicts = [
  {
    state: 0,
    info: '商品已拍下，等待买家付款',
    tip: '请务必等待订单状态变更为“买家已付款，等待卖家发货”后再进行发货。',
  },
  {
    state: 1,
    info: '等待商家发货',
    infoTip: '买家已付款至薪发现待结算账户，请尽快发货，否则买家有权申请退款。',
    tip:
      '如果无法发货，请及时与买家联系并说明情况后进行退款；<br>买家申请退款后，须征得买家同意后再发货，否则买家有权拒收货物；<br>买家付款后超过7天仍未发货，将有权申请薪发现客服介入发起退款维权；',
  },
  {
    state: 2,
    info: '商家已发货，等待交易成功',
    tip:
      '交易成功后，薪发现将把货款结算至你的店铺账户余额，你可申请提现；<br>请及时关注你发出的包裹状态，确保能配送至买家手中；<br>如果买家表示未收到货或者货物有问题，请及时联系买家积极处理，友好协商；',
  },
  {
    state: 3,
    info: '交易完成',
    infoTip: '薪发现已将货款结算至你的店铺余额账户，请注意查收。',
    tip: '交易已成功，如果买家提出售后要求，请积极与买家协商，做好售后服务。',
  },
  {
    state: 4,
    info: '交易关闭',
    infoTip: '',
    tip: '',
  },
  {
    state: 5,
    info: '买家已下单，等待拼团成功',
    infoTip: '买家已付款至薪发现待结算账户，若拼团失败，将自动退款给买家。',
    tip: '请务必等待订单状态变更为“买家已付款，等待卖家发货”后再进行发货。',
  },
  {
    state: 6,
    info: '买家已付款，等待订单核销',
  },
]
const columns = [
  {
    title: '商品名称',
    scopedSlots: {
      customRender: 'goodsName',
    },
  },
  {
    title: '单价(元)',
    dataIndex: 'price',
  },
  {
    title: '数量(件)',
    dataIndex: 'count',
  },
  {
    title: '优惠(元)',
    dataIndex: 'discountAmount',
  },
  {
    title: '小计(元)',
    dataIndex: 'subtotalAmount',
  },
  {
    title: '发货状态',
    scopedSlots: {
      customRender: 'deliveryState',
    },
  },
]
export default {
  name: 'PageOrderDetail',

  props: {
    shopOrderId: String,
  },

  components: {
    DialogDelivery,
    DialogUpdateDelivery,
    ModalBatchPay,
  },

  setup (props, { root, emit }) {
    const loading = ref(false)
    const orderDetail = ref({})
    const stateInfo = computed(() => {
      const { orderState, orderType } = orderDetail.value
      const matching = stateDicts.find((x) => x.state === orderState)
      return {
        ...(matching || {}),
        infoTip:
          orderType === 2 && orderState === 4 ? '拼团失败' : matching ? matching.infoTip : '',
      }
    })
    const trackList = ref([])
    async function getDetail () {
      loading.value = true
      const [err, data] = await wrapAwait(order.getBatchOrderDetail(props.shopOrderId))
      loading.value = false
      if (!err) {
        orderDetail.value = data
        trackList.value =
          data.logisticsTrack && data.logisticsTrack.list ? data.logisticsTrack.list : []
      } else {
        root.$message.warn('订单详情获取失败，请稍后重试')
        root.$closeCurrentView()
      }
    }
    getDetail()
    onMounted(() => {
      root.$bus.$on('right-protection-done', getDetail)
      root.$bus.$on('service-update', getDetail)
      root.$bus.$on('delivery-update', getDetail)
    })
    onUnmounted(() => {
      root.$bus.$off('right-protection-done', getDetail)
      root.$bus.$off('service-update', getDetail)
      root.$bus.$off('delivery-update', getDetail)
    })

    function handlePayMode (value) {
      const matching = payModes.find((x) => +x.value === +value)
      return matching ? matching.name : ''
    }
    function handleDeliveryStatus (value) {
      const noTrack = '暂无物流信息'
      if (!value) return noTrack
      const matching = deliveryStatus.find((x) => x.value === value)
      return matching ? matching.name : noTrack
    }

    // 发货
    const dialogDeliveryShow = ref(false)
    // 修改物流
    const updateDeliveryShow = ref(false)

    // 买家支付
    const pendingPay = ref('')
    const batchPay = ref(null)
    const paying = ref(false)
    const showPayModalFlag = ref(false)
    function handlePay (orderId) {
      showPayModalFlag.value = true
      pendingPay.value = orderId
    }
    async function doPay () {
      paying.value = true
      const [err, data] = await wrapAwait(batchPay.value.submit())
      paying.value = false
      if (!err) {
        pendingPay.value = ''
        showPayModalFlag.value = false
        message.success('提交成功')
        getDetail()
      }
    }

    // 关闭订单
    const closing = ref('')
    async function closeOrder (orderId) {
      closing.value = orderId
      const [err, data] = await wrapAwait(order.cancelBatchOrder(orderId))
      closing.value = ''
      if (err) {
        message.error(err.message || '关闭订单失败')
      } else {
        message.success('关闭订单成功')
        getDetail()
      }
    }

    // 确认收货
    const confirming = ref('')
    async function confirmOrder (orderId) {
      confirming.value = orderId
      const [err, data] = await wrapAwait(order.confirmBatchOrder(orderId))
      confirming.value = ''
      if (err) {
        message.error(err.message || '确认收货失败')
      } else {
        message.success('确认收货成功')
        getDetail()
      }
    }

    function getUserReturnText (returnState) {
      const matching = orderReturnStates.find((x) => x.value === +returnState)
      return matching ? matching.orderBtn : ''
    }

    return {
      shopId: getSession(SHOPID),
      columns,
      loading,
      orderDetail,
      stateInfo,
      trackList,
      getDetail,
      handlePayMode,
      handleDeliveryStatus,
      dialogDeliveryShow,
      updateDeliveryShow,
      handlePay,
      pendingPay,
      batchPay,
      doPay,
      paying,
      showPayModalFlag,
      closing,
      closeOrder,
      confirming,
      confirmOrder,
      getUserReturnText,
    }
  },
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  font-size: 14px;
  color: #333;
  .btn-back {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 32px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: 14px;
    color: #fff;
    background-color: #f39800;
    .icon-back {
      margin-right: 3px;
    }
  }
  .order-info span {
    margin-right: 34px;
  }
}
.order-state {
  .state-info-box {
    display: flex;
    min-height: 160px;
    margin-top: 20px;
    border: 1px solid #dcdfe5;
    .state-info {
      flex: none;
      width: 33.8%;
      box-sizing: border-box;
      padding: 16px 20px;
      border-right: 1px solid #dcdfe5;
      .info-tit {
        font-size: 20px;
        line-height: 30px;
      }
      .info-info {
        margin-bottom: 3px;
        color: #666;
        line-height: 24px;
      }
      .count-down {
        display: inline-block;
        ::v-deep .ant-statistic-content {
          color: #f39800;
        }
      }
    }
    .state-progress {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-finish {
        display: block;
        margin-top: -4px;
        font-size: 18px;
      }
      .icon-wait {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        margin-top: -4px;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        background-color: #999;
      }
      ::v-deep .ant-steps-item-process .icon-wait {
        background-color: #1890ff;
      }
      ::v-deep .ant-steps-item-title {
        font-size: 14px;
      }
      ::v-deep .ant-steps-item-description {
        font-size: 12px;
        color: #999;
      }
      ::v-deep .ant-steps-dot .ant-steps-item-tail::after,
      ::v-deep .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
        width: calc(100% - 24px);
      }
      ::v-deep .ant-steps-dot .ant-steps-item-icon,
      ::v-deep .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
        margin-left: 61px;
      }
    }
  }
  .tips {
    display: flex;
    padding: 12px 18px;
    border: 1px solid #dcdfe5;
    border-top: none;
    line-height: 24px;
    .label {
      color: #f39800;
    }
  }
  .tips.service {
    align-items: center;
  }
}
.logistics-info {
  padding: 12px 20px;
  margin-top: 20px;
  border: 1px solid #dcdfe5;
  .info-item {
    margin: 4px 0;
    font-size: 14px;
    color: #666;
    line-height: 20px;
    span {
      color: #f39800;
    }
  }
  .track-list {
    max-height: 260px;
    overflow-y: auto;
    padding-top: 12px;
  }
}
.receive-info {
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 14px;
  background-color: #f3f6f8;
  .info-title {
    color: #333;
    line-height: 32px;
  }
  .info-item {
    margin: 4px 0;
    color: #666;
    line-height: 20px;
  }
}
.goods-price-info {
  margin-top: 20px;
  text-align: right;
  .price-info {
    margin-top: 18px;
    padding: 0 20px;
    font-size: 14px;
    color: #666;
    line-height: 22px;
    span {
      display: inline-block;
      min-width: 72px;
    }
    .actual {
      margin-top: 10px;
      span {
        font-size: 16px;
        color: #df4545;
      }
    }
  }
  .btn-refund {
    padding: 0;
    margin-right: 6px;
  }
}
.goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
  }
  .sku-info {
    max-width: 130px;
    font-size: 12px;
  }
}
.action {
  margin-right: 5px;
}
</style>
